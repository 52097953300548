import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import notificationReducer from '../../modules/notifications/reducer';
import history from '../../utils/history';

export default function createReducer(injectedReducers = {}) {
	return combineReducers({
		router: connectRouter(history),
		form: formReducer,
		notifications: notificationReducer,
		...injectedReducers,
	});
}
