import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { ADD_NEW_MESSAGE, GET_MESSAGES, setIsMessagesLoadingAction, setMessagesAction } from './actions';
import { errorHandler } from '../../api/utils';
import { addNewMessageRequest, getMessagesRequest } from '../../api/messages-requests';
import { getRawMessagesSelector } from './selectors';
import { getCompanyIdSelector, getUserIdSelector } from '../profile/selectors';
import { createReqMessageCreateChannels, createReqMessagesUpdateChannels } from '../../api/sockets';

export function* getMessagesSaga({ payload: id }) {
	try {
		yield put(setIsMessagesLoadingAction(true));

		const { data, message, toast } = yield call(getMessagesRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setMessagesAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsMessagesLoadingAction(false));
	}
}

export function* addNewMessageSaga({ payload: { id, messageText } }) {
	try {
		const { data, message, toast } = yield call(addNewMessageRequest, { message: messageText }, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const messages = yield select(getRawMessagesSelector());

			const messageDate = data.created_at.split('T')[0];

			const dayIndex = messages.findIndex(item => item.date === messageDate);

			if (dayIndex === -1) {
				yield put(setMessagesAction([...messages, { date: messageDate, messages: [data] }]));

				return;
			}

			const newMessages = messages.map(item => {
				if (item.date === messageDate) {
					return { date: item.date, messages: [...item.messages, data] };
				}

				return item;
			});

			yield put(setMessagesAction(newMessages));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export function* socketReqMessageCreateSaga() {
	const companyId = yield select(getCompanyIdSelector());

	const channel = yield call(() => createReqMessageCreateChannels(companyId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);

		const query = new URLSearchParams(window.location.search);

		if (
			query.get('modal') === 'reqs_details' &&
			query.get('tab') === 'chat' &&
			data?.id === Number(query.get('id'))
		) {
			const messages = yield select(getRawMessagesSelector());

			const messageDate = data.message.created_at.split('T')[0];

			const dayIndex = messages.findIndex(item => item.date === messageDate);

			if (dayIndex === -1) {
				yield put(setMessagesAction([...messages, { date: messageDate, messages: [data.message] }]));
			} else {
				const newMessages = messages.map(item => {
					if (item.date === messageDate) {
						return { date: item.date, messages: [...item.messages, data.message] };
					}

					return item;
				});

				yield put(setMessagesAction(newMessages));
			}
		}
	}
}

export function* socketReqMessagesUpdateSaga() {
	const userId = yield select(getUserIdSelector());

	const channel = yield call(() => createReqMessagesUpdateChannels(userId));

	if (!channel) return;

	while (true) {
		const data = yield take(channel);

		const query = new URLSearchParams(window.location.search);

		if (
			query.get('modal') === 'reqs_details' ||
			query.get('tab') === 'chat' ||
			data?.id === Number(query.get('id'))
		) {
			const messages = yield select(getRawMessagesSelector());

			const newMessages = messages.map(item => {
				return {
					date: item.date,
					messages: item.messages.map(message => {
						const curMessage = data.messages.find(msg => msg.id === message.id);

						if (curMessage) {
							return curMessage;
						}

						return message;
					}),
				};
			});

			yield put(setMessagesAction(newMessages));
		}
	}
}

export default function* chatModalSaga() {
	yield takeEvery(GET_MESSAGES, getMessagesSaga);
	yield takeEvery(ADD_NEW_MESSAGE, addNewMessageSaga);
}
