/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { AddressFormView } from './address-form-view';
import { ADDRESSES_FORM_NAME } from '../../constants';
import {
	getAddressesOptionsSelector,
	getAddressesSelector,
	getAddressSearchValueSelector,
	getAddressValueSelector,
	getFormInitialValuesSelector,
	getIsAddressesOptionsLoadingSelector,
	getIsAddressInfoLoadingSelector,
} from '../../selectors';
import { getAddressesOptionsAction, setAddressSearchValueAction } from '../../actions';
import { validate } from '../../utils';

const AddressFormWrapper = ({
	addressesOptions,
	isAddressesLoading,
	addressesSearchValue,
	onSetAddressSearchValue,
	onGetAddressesOptions,
	addresses,
	onFieldChange,
	address,
	isAddressInfoLoading,
}) => {
	useEffect(() => {
		if (address) {
			onSetAddressSearchValue(address);
			onGetAddressesOptions();
		}
	}, [address]);

	const handleAddressSearchChange = ({ target: { value } }) => {
		onSetAddressSearchValue(value);
		onGetAddressesOptions();
	};

	const handleAddressChange = value => {
		const currentAddress = addresses.find(address => address.value === value);

		if (currentAddress) {
			Object.entries(currentAddress.data).forEach(([key, value]) => {
				onFieldChange(ADDRESSES_FORM_NAME, key, value);
			});

			onFieldChange(ADDRESSES_FORM_NAME, 'address', currentAddress.value);

			onSetAddressSearchValue(currentAddress.value);
		}
	};

	return (
		<AddressFormView
			addressesOptions={addressesOptions}
			isAddressesLoading={isAddressesLoading}
			onAddressSearchChange={handleAddressSearchChange}
			onAddressChange={handleAddressChange}
			addressesSearchValue={addressesSearchValue}
			isAddressInfoLoading={isAddressInfoLoading}
		/>
	);
};

const withForm = reduxForm({
	form: ADDRESSES_FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(AddressFormWrapper);

const mapStateToProps = createStructuredSelector({
	initialValues: getFormInitialValuesSelector(),
	addresses: getAddressesSelector(),
	addressesOptions: getAddressesOptionsSelector(),
	addressesSearchValue: getAddressSearchValueSelector(),
	isAddressesLoading: getIsAddressesOptionsLoadingSelector(),
	address: getAddressValueSelector(),
	isAddressInfoLoading: getIsAddressInfoLoadingSelector(),
});

const mapDispatchToProps = {
	onSetAddressSearchValue: setAddressSearchValueAction,
	onGetAddressesOptions: getAddressesOptionsAction,
	onFieldChange: change,
};

const AddressForm = connect(mapStateToProps, mapDispatchToProps)(withForm);

AddressFormWrapper.propTypes = {
	addressesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isAddressesLoading: PropTypes.bool.isRequired,
	onSetAddressSearchValue: PropTypes.func.isRequired,
	onGetAddressesOptions: PropTypes.func.isRequired,
	addressesSearchValue: PropTypes.string.isRequired,
	addresses: PropTypes.array.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	address: PropTypes.string,
	isAddressInfoLoading: PropTypes.bool.isRequired,
};

export { AddressForm };
